<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="()=>{
            this.$router.go(-1);
        }" :api="this.api"/>
        <v-row>
            <v-col align="start" justify="start">
                <v-btn
                @click="()=>{
                    this.$router.go(-1)
                }"
                class="mb-3"
                color="primary">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                style="min-height:700px"
                lg="6"
                cols="12"
                md="12"
                sm="12">
                <v-skeleton-loader v-if="isLoadingXero" type="table" ref="skeleton">
                </v-skeleton-loader>
                <v-row
                    v-if="!isLoadingXero">
                    <v-col
                        lg="12"
                        cols="12"
                        md="12">
                        <v-card
                            class="pa-3"
                            elevation-1>
                            <v-toolbar
                                class="white--text text-h5 mb-4"
                                color="secondary">
                                Invoice
                            </v-toolbar>
                            <v-spacer>
                            </v-spacer>
                            <AError
                                :api="this.api"
                            />
                            <v-row
                                class="mt-3">
                                <v-col
                                    cols="6"
                                    lg="6"
                                    md="12">
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            v-model="data.company_name"
                                            dense 
                                            outlined
                                            label="Company">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-textarea
                                            v-model="data.address"
                                            dense 
                                            outlined
                                            @change="checkAddress" 
                                            label="Address">
                                        </v-textarea>
                                    </div>
                                    <div
                                        class="d-flex justify-start"
                                        v-if="isShowSetAsAddress">
                                        <v-checkbox
                                            style="margin-top:-20px"
                                            v-model="data.is_set_default_address"
                                            label="Set as current address">
                                        </v-checkbox>

                                    </div>
                                    <!-- <v-card
                                        elevation-0
                                        outlined
                                        class="pa-4">
                                        <div
                                            class="d-flex justify-start mb-2">
                                            <h4
                                                class="pb-2">
                                                Email
                                            </h4>

                                        </div>
                                        <div
                                            class="d-flex justify-start">
                                            <v-text-field
                                                v-model="data.email"
                                                dense 
                                                outlined 
                                                label="Send email to ">
                                            </v-text-field>
                                        </div>
                                        <div
                                            class="d-flex justify-start">
                                            <v-combobox
                                                v-model="cc_email"
                                                label="CC email to"
                                                multiple
                                                dense
                                                chips
                                                outlined
                                                hint="Press enter button to confirm the email ( max 3 emails)"
                                                @change="handleChange"
                                            >
                                                <template v-slot:selection="{item,index}">
                                                        <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-chip
                                                                v-on="on"
                                                                class="my-2"
                                                                label
                                                                outlined
                                                                close
                                                                @click:close="removeItem(index)"
                                                                color="green"
                                                                style="width:100%;text-overflow: ellipsis;overflow:hidden"
                                                                >
                                                                {{ item }}
                                                            </v-chip>
    
                                                        </template>
                                                        <span>
                                                            {{ item }}
                                                        </span>
                                                    </v-tooltip>
                                                </template>
                                            </v-combobox>
                                        </div>
                                        <div
                                            class="d-flex justify-start ">
                                            <v-combobox
                                                :loading="isLoadingPIC"
                                                v-model="data.pic_email" :items="picOptions" dense outlined
                                                label="Person in charge"
                                                @change="checkPICEmail">
                                            </v-combobox>
                                        </div>
                                        <div
                                            class="d-flex justify-end mt-0 pt-0">
                                            <v-btn
                                                style="margin-top:-20px"
                                                small
                                                @click="addCCEmail(data.pic_email)"
                                                color="primary"
                                                >
                                                Add to cc
                                            </v-btn>
                                        </div>
                                    </v-card> -->
                                </v-col>
                                <v-col
                                    cols="6"
                                    lg="6"
                                    md="12">
                                    <!-- <div
                                        class="d-flex justify-start">
                                            <a
                                            v-if="data.invoice_link!=null"
                                            :href="data.invoice_link"
                                            target="_blank">
                                            View invoice
                                        </a>
                                    </div> -->
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            v-model="data.invoice_no"
                                            dense 
                                            outlined 
                                            label="Invoice No.">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-textarea
                                            v-model="data.description"
                                            dense 
                                            outlined
                                            label="Description">
                                        </v-textarea>
                                    </div>
                                    <ComponentDateModal
                                    label="Issued date"
                                    :data="data.issued_at"
                                    @updateDateData="(resp)=>{
                                        this.data.issued_at = resp;
                                    }"/>
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            v-model="data.client_no"
                                            dense 
                                            outlined 
                                            label="Client no">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            label="Subtotal ( RM )"
                                            dense
                                            outlined
                                            v-model="data.subtotal">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            dense 
                                            outlined
                                            v-model="data.tax_amount"
                                            label="Tax Amount ( RM )">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            dense 
                                            outlined 
                                            label="Total ( RM )"
                                            v-model="data.total">
                                        </v-text-field>
                                    </div>
                                    <div
                                        class="d-flex justify-start">
                                        <v-text-field
                                            dense 
                                            outlined
                                            label="Amount due ( RM )"
                                            v-model="data.amount_due">
        
                                        </v-text-field>
        
                                    </div>
                                    <div
                                        class="d-flex justify-end">
                                        <v-btn
                                            @click="generateInvoice"
                                            text
                                            :loading="isLoadingGeneratePdf"
                                            color="primary">
                                            Review invoice
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="sendEmail"
                                            :loading="isLoadingSendEmail">
                                            Save modified invoice 
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                style="min-height:700px"
                lg="6"
                sm="12">
                <v-skeleton-loader v-if="isLoadingPdf" type="image" ref="skeleton">
                </v-skeleton-loader>
                <PageReviewXeroInvoice
                v-else :url="pdfUrl"/>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
import PageReviewXeroInvoice from '../../views/attachment/PageReviewXeroInvoice.vue';
// import jsPDF from 'jspdf';
export default {
    components:{
    ASuccessWithoutReload,
    ComponentDateModal,
    PageReviewXeroInvoice,
},
    computed: mapState({
    //
}),
    props:[
    ],
    data () {
        return{
        pdfUrl:null,
        search:"",
        picOptions:null,
        isLoadingXero:true,
        isLoadingPdf:true,
        isLoadingPIC:true,
        isLoadingSendEmail:false,
        data:{
            'company_id':null,
            'user_id':null,
            'company_name':"",
            'user_name':null,
            'email':null,
            'address':"",
            'invoice_no':"",
            'invoice_link':"",
            'client_no':null,
            'subtotal':0,
            'tax_amount':0,
            'amount_due':0,
            'total':0,
            'issued_at':null,
            // 'pic_email':null,
            // 'cc_email':[null],
            // 'is_assigned_pic':false,
            'is_set_default_address':false,
        },
        cc_email:[],
        isshowAssignedPIC:false,
        isShowSetAsAddress:false,
        pdf:null,
        isPending:false,
        isLoadingGeneratePdf:false,
        currentPICEmail:null,
        currentAddress:null,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }

    };
    },
    
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
            this.isLoadingSendEmail=false;
            this.isLoadingXero = false;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=== "getXeroInvoice") {
                this.data = resp.data;
                if(this.data.issued_at != null ){
                    let tempIssuedAt = this.$_.clone(this.data.issued_at);
                    tempIssuedAt = tempIssuedAt.split(" ")[0];
                    this.data.issued_at = tempIssuedAt;
                }
                if(this.data.address != null && this.data.address !="") {
                    this.currentAddress = this.data.address;
                    this.data.is_set_default_address =false;
                    this.isShowSetAsAddress = false;
                }
                else{
                    this.isShowSetAsAddress = true;
                }
                if(this.data.address === null || this.data.address==="null") {
                    this.data.address = ""
                }
                this.generateInvoice();
                this.isLoadingXero = false;
            }
         
            if(resp.class ==="getUserMultipleRole") {
                let tempData = resp.data.map(obj=>obj.email);
                this.picOptions =tempData;

            }
            if(resp.class === "EditPdf") {
                // this.isLoadingGeneratePdf = false;
                this.convertBase64ToPdf(resp.data);
                this.isLoadingPdf = false;
            }
            if(resp.class === "sendXeroInvoice") {
                this.isLoadingSendEmail = false;
                this.api.isSuccesful = true;
                this.api.success = "Succesfully save modified invoice";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$axios.all([
                this.$api.fetch(this.fetchXeroInvoice()),
                this.$api.fetch(this.fetchPIC()),
                this.$api.fetch(this.fetchClientPIC()),

            ])
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchXeroInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/invoices/"+this.$router.history.current.params.id+"?type="+this.$router.history.current.params.type;
            return tempApi;
        },
        fetchClientPIC () {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/clientpic/"+this.$router.history.current.params.company_id;
            return tempApi;
        },
        fetchPIC() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/multiple";
            tempApi.params = {
                'roles':['salesperson','customer_service','vision_admin','sales_team_lead','sales_manager'],
            }
            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
            this.$router.go(-1);
        },
        generateInvoice() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/pdf/edit";
            let formData = new FormData;
            Object.entries(this.data).forEach(([key,value])=>{
                formData.append(key,value);
            })

            tempApi.params = formData;
            this.isLoadingGeneratePdf = true;
            this.$api.fetch(tempApi);
        },
        convertBase64ToPdf(base64) {
            this.pdf = null;
            const binaryString = atob(base64);

            // Convert binary string to array buffer
            const byteArray = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                byteArray[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            this.pdf = this.$_.clone(blob);
        

            const url = URL.createObjectURL(blob);
            this.pdfUrl = url;
            this.isLoadingGeneratePdf = false;
        },
        sendEmail() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/xero/invoices/email/"+this.$router.history.current.params.id;
            let formData = new FormData;
            if(this.cc_email!=null) {
                formData.append('cc_email',this.cc_email);
            }
            formData.append('email',this.data.email);
            formData.append('attachment',this.pdf);
            Object.entries(this.data).forEach(([key,value])=>{
                formData.append(key,value);
            })
            tempApi.params = formData;
            this.isLoadingSendEmail = true;
            this.$api.fetch(tempApi);
        },
        handleChange() {

            if(Object.keys(this.cc_email).length>3) {
                this.api.isError = true;
                this.api.error ="You can only cc to max 3 emails";
                this.cc_email.pop();
                return 1;
            }
            this.api.isError = false;
        },
        removeItem(index) {
            this.cc_email.splice(index,1);
        },
        checkPICEmail() {
            this.isshowAssignedPIC = false;
            this.data.is_assigned_pic=false;
            if(this.data.pic_email != this.currentPICEmail) {
                //if user want to change the pic email, allow user to tick the checkbox to set if want to use this email as current pic email
                this.isshowAssignedPIC = true;
                this.data.is_assigned_pic = false
            }
        },
        checkAddress() {
            this.isShowSetAsAddress = false;
            this.data.is_set_default_address = false;
            if(this.data.address != this.currentAddress && this.data.address !="") {
                this.isShowSetAsAddress = true;
                this.data.is_set_default_address = false;
            }
        },
        addCCEmail(email){
            this.api.isError = false;
            if(!this.cc_email.includes(email)) {
                if(Object.keys(this.cc_email).length>3) {
                    this.api.isError = true;
                    this.api.error ="You can only cc to max 3 emails";
                    // this.cc_email.pop();
                    return 1;
                }
                this.cc_email.push(email);
                return 1;

            }
            this.api.isError = true;
            this.api.error = "Email already being added to cc";
            return 1;

        }
    }
}
</script>