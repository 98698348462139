<template>
        <iframe
            v-if="url!=null"
            :src="url" width="100%" height="100%" frameborder="0">
        </iframe>
        <transition name="fade"
            v-else>
            <v-alert
            dense
            border="left"
            type="warning"
            >
            Cannot load PDF
            </v-alert>
        </transition>

</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
},
    computed: mapState({
    //
}),
    props:[
        'url'
    ],
    data: () => ({
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
        },
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        }
    }
}
</script>